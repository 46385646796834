<template>
  <div v-show="treeData.show"
       class="left-wrap">
    <div class="school-name-wrap">
      <img :src="iconUrl"
           alt="">
      <el-popover placement="bottom"
                  trigger="click"
                  :open-delay="200">
        <div style="max-width: 200px;line-height: 24px">
          <div>{{ schoolName }}{{ gradeLevelName }}组织架构</div>
        </div>
        <div slot="reference">
          <div class="school-name">{{ schoolName }}{{ gradeLevelName }}组织架构</div>
        </div>
      </el-popover>
    </div>
    <div class="input-wrap">
      <el-input placeholder="模糊查询实时显示结果"
                v-model="filterText"
                clearable
                suffix-icon="el-icon-search" />
    </div>
    <el-scrollbar class="tree-wrap"
                  wrap-style="overflow-x:hidden;">
      <el-tree node-key="id"
               ref="tree"
               :data="treeData.dataList"
               :props="treeData.defaultProps"
               :expand-on-click-node="false"
               default-expand-all
               highlight-current
               :filter-node-method="filterNode"
               @node-click="nodeCLick">
        <div slot-scope="scopeData">
          <div>
            <span>{{ scopeData.node.label }}</span>
            <span v-if="scopeData.node.level === 1"
                  class="stage-class"
                  :style="getStageStyle(scopeData.data.greadStage)">{{ getStageName(scopeData.data.greadStage) }}</span>
            <!--<span v-if="scopeData.data.organType === '4'">{{ getClassTypeName(scopeData.data) }}</span>-->
          </div>
        </div>
      </el-tree>
    </el-scrollbar>
    <!-- <el-cascader v-model="studentValue"
                 :options="list"
                 :props="tableProps"
                 clearable></el-cascader> -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    data: Object
  },
  data () {
    return {
      leftX: '',
      topY: '',
      filterText: '',
      treeData: {
        placeholder: '',
        value: '',
        show: '',
        dataList: [],
        defaultProps: {}
      },
      status: 0,
      studentValue: [],
      list:[],
      tableProps:{
        label:'name',
        multiple: false,
        value: 'id'
      }
    }
  },
  computed: {
    ...mapState({
      schoolName: (state) => state.schoolName,
      schoolStageList: state => state.schoolStageList,
    }),
    iconUrl () {
      return require('@/assets/images/structure-icon.png')
    },
    gradeLevelName () {
      const gradeLevelName = this.$store.state.gradeLevelName
      if (gradeLevelName.includes('&lt')) {
        return gradeLevelName.replace(/&lt;/g, '-')
      } else if (gradeLevelName.includes('<')) {
        return gradeLevelName.replace(/</g, '-')
      }
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val);
    },
    'data': {
      handler (val) {
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.$refs.tree.filter()
    console.log(this.data, 'data');
  },
  methods: {
    init () {
      Object.keys(this.data).forEach((p) => {
        this.treeData[p] = this.data[p];
      })
      // let jsonList = JSON.stringify(this.treeData.dataList)
      // let jsonData = jsonList.replace(/\"children\":\[]/g, "\"children\":null")
      // this.list=JSON.parse(jsonData)
      // // this.list = this.treeData.dataList
      // console.log(this.list, 'list');
    },
    getClassTypeName (data) {
      if (data.type === '1') {
        return '-行政班'
      } else if (data.type === '2') {
        return '-教学班'
      } else {
        return '-普通班'
      }
    },
    getStageName (data) {
      const find = this.schoolStageList.find(i => i.schoolStage === data)
      if (find && find.schoolStageName) {
        return find.schoolStageName[0]
      }
    },
    getStageStyle (data) {
      const find = this.schoolStageList.find(i => i.schoolStage === data)
      if (find && find.schoolStageName) {
        const name = find.schoolStageName[0]
        switch (name) {
          case '幼':
            return 'background: #faf0f7;color: #dca0c7;padding: 2px 4px;'
          case '小':
            return 'background: #faf4ed;color: #e0c0a2;padding: 2px 4px;'
          case '初':
            return 'background: #EBF7F6;color: #7ACCC5;padding: 2px 4px;'
          case '高':
            return 'background: #F0F2FA;color: #ADB5D9;padding: 2px 4px;'
          default:
            break;
        }
      }
    },
    inputFocus (ev) {
      if (!this.data.showAlways) {
        this.data.show = !this.data.show
      }
    },
    filterNode (value, data) {
      if (!value) return this.data.filter(data)
      return data.name.includes(value) && this.data.filter(data)
    },
    nodeCLick (data) {
      if (this.data.filter(data)) {
        this.data.value = data.name
        this.data.hid = data.id
        this.$emit('node', data)
        console.log(data,'nodeCLicknodeCLick');
      }
    },
  },
}
</script>
<style scoped lang="scss">
.left-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.school-name-wrap {
  display: flex;
  padding: 14px 16px;
  border-bottom: 1px dashed #e6e6e6;

  img {
    width: 14px;
    height: 12px;
    margin-right: 8px;
    margin-top: 5px;
  }

  .school-name {
    font-size: 14px;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis; /* 超出部分省略号 */
    word-break: break-all; /* break-all(允许在单词内换行。) */
    display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
    -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
    -webkit-line-clamp: 2; /* 显示的行数 */
  }
}

.input-wrap {
  padding: 12px 16px;

  /deep/ .el-input__inner {
    border: 0;
    background-color: #f7f7f7;
  }
}

.tree-wrap {
  padding-bottom: 10px;

  .el-tree {
    margin-left: 10px;
    color: #333333;
    font-size: 14px;
  }

  /deep/ .el-tree-node__content {
    height: 45px;
    border-bottom: 1px solid #f0f7fe;
  }

  // 选中当前node的样式
  /deep/
    .el-tree--highlight-current
    .el-tree-node.is-current
    > .el-tree-node__content {
    background-color: #dcebfa !important;
    color: #3C7FFF;
    font-weight: bold;
  }
}
.stage-class {
  margin-left: 5px;
  border-radius: 2px;
  line-height: 11px;
  font-size: 12px;
  background: #ebf7f6;
  color: #7accc5;
}
</style>
